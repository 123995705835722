import { Code, Server, Palette, Database } from "lucide-react";

export const skillsData = [
    {
      title: "Frontend Development",
      icon: <Code className="w-6 h-6 text-indigo-500" />,
      skills: [
        { name: "React", level: 4 },
        { name: "TypeScript", level: 4 },
        { name: "Tailwind CSS", level: 5 },
        { name: "Next.js", level: 3 },
        { name: "Vite.js", level: 4 },
        { name: "Responsive Design", level: 5 },
      ],
    },
    {
      title: "Backend Development",
      icon: <Server className="w-6 h-6 text-indigo-500" />,
      skills: [
        { name: "Node.js", level: 4 },
        { name: "Python Flask", level: 3 },
        { name: "PHP Symfony", level: 3 },
        { name: "PostgreSQL", level: 4 },
        { name: "RESTful APIs", level: 5 },
      ],
    },
    {
      title: "Soft Skills",
      icon: <Palette className="w-6 h-6 text-indigo-500" />,
      skills: [
        { name: "Teamwork", level: 5 },
        { name: "Detail-oriented", level: 4 },
        { name: "Empathy", level: 5 },
        { name: "Interpersonal communication", level: 4 },
        { name: "Problem-solving", level: 5 },
      ],
    },
    {
      title: "Database & DevOps",
      icon: <Database className="w-6 h-6 text-indigo-500" />,
      skills: [
        { name: "PostgreSQL", level: 4 },
        { name: "Docker", level: 3 },
        { name: "CI/CD", level: 3 },
        { name: "Git", level: 5 },
        { name: "NoSQL", level: 3 },
      ],
    },
  ];