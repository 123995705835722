import React, { useState, useRef } from "react";
import Hero from "../pages/Hero";
import ProjectsSection from "../components/ProjectsSection/ProjectsSection";
import BlogSection from "../components/BlogSection/BlogSection";
import ContactSection from "../components/ContactSection/ContactSection";
import CareerSection from "../components/Career/CareerSection";
import SkillsSection from "../components/SkillsSection/SkillsSection";
import RetroBackground from "../assets/Background/RetroBackground";

const Portfolio: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDesktopView, setIsDesktopView] = useState(false);
  const projectsRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const scrollTo = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="min-h-screen bg-violet-50 font-['VT323'] relative overflow-hidden">
      <RetroBackground />
      <div className="absolute inset-0 bg-[linear-gradient(rgba(99,102,241,0.03)_1px,transparent_1px),linear-gradient(90deg,rgba(99,102,241,0.03)_1px,transparent_1px)] bg-[size:20px_20px] [mask-image:radial-gradient(ellipse_80%_50%_at_50%_0%,#000_70%,transparent_110%)]" />

      <div
        className={`transition-opacity duration-500 ${
          isDesktopView ? "opacity-0 pointer-events-none" : "opacity-100"
        } absolute inset-0 overflow-auto`}
      >
        <div className="max-w-6xl mx-auto space-y-16 relative z-10 p-4 md:p-8">
          <Hero
            onProjectsClick={() => scrollTo(projectsRef)}
            onContactClick={() => scrollTo(contactRef)}
          />
          
          <section ref={projectsRef}>
            <h2 className="text-3xl font-bold text-indigo-700 mb-6 border-b-2 border-indigo-500 pb-2">
              Featured Projects
            </h2>
            <ProjectsSection isDesktopView={isDesktopView} />
          </section>

          <section>
            <h2 className="text-3xl font-bold text-indigo-700 mb-6 border-b-2 border-indigo-500 pb-2">
              Latest Blog Posts
            </h2>
            <BlogSection isDesktopView={isDesktopView} />
          </section>

          <section>
            <h2 className="text-3xl font-bold text-indigo-700 mb-6 border-b-2 border-indigo-500 pb-2">
              Career Journey
            </h2>
            <CareerSection />
          </section>

          <section>
            <h2 className="text-3xl font-bold text-indigo-700 mb-6 border-b-2 border-indigo-500 pb-2">
              Skills & Expertise
            </h2>
            <SkillsSection />
          </section>

          <section ref={contactRef}>
            <h2 className="text-3xl font-bold text-indigo-700 mb-6 border-b-2 border-indigo-500 pb-2">
              Get in Touch
            </h2>
            <ContactSection />
          </section>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
