import React, { useState } from "react";
import { Search, ArrowLeft, Calendar, Clock, Tag } from "lucide-react";
import WindowContainer from "../components/WindowContainer/WindowContainer";
import RetroButton from "../components/RetroButton/RetroButton";
import { useNavigate, useLocation } from "react-router-dom";
import { blogPosts } from "../assets/components/blog-posts";
//import LoadingScreen from "../components/LoadingScreen/LoadingScreen";

const CATEGORIES = ["All", "Web Dev", "DevOps", "UI/UX", "Career"];

const BlogPage: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktopView = location.state?.isDesktopView || false;

  const handleBack = () => {
    navigate("/");
  };

  const filteredPosts = blogPosts.filter((post) => {
    const categoryMatch = selectedCategory === "All" || post.category === selectedCategory;
    const searchMatch = post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        post.excerpt.toLowerCase().includes(searchTerm.toLowerCase());
    return categoryMatch && searchMatch;
  });

  const content = (
    <div className="space-y-6">
      <div className="flex flex-col md:flex-row justify-between gap-4">
        <div className="relative">
          <input
            type="text"
            placeholder="SEARCH_POSTS"
            className="w-full md:w-64 px-4 py-2 bg-white border-2 border-indigo-500 rounded shadow-[2px_2px_0px_0px_#6366f1] focus:outline-none"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute right-3 top-2.5 w-5 h-5 text-indigo-500" />
        </div>

        <div className="flex flex-wrap gap-2">
          {CATEGORIES.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`px-3 py-1 border-2 border-indigo-500 rounded ${
                selectedCategory === category
                  ? "bg-indigo-500 text-white"
                  : "bg-white text-indigo-500 hover:bg-indigo-100"
              } shadow-[2px_2px_0px_0px_#6366f1]`}
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {filteredPosts.map((post) => (
          <div
            key={post.id}
            className="bg-white border-2 border-indigo-500 rounded overflow-hidden shadow-[4px_4px_0px_0px_#6366f1]"
          >
            <img
              src={post.image}
              alt={post.title}
              className="w-full aspect-video object-cover"
            />
            <div className="p-4">
              <div className="flex items-center space-x-4 text-indigo-500 text-sm mb-2">
                <div className="flex items-center">
                  <Calendar className="w-4 h-4 mr-1" />
                  <span>{post.date}</span>
                </div>
                <div className="flex items-center">
                  <Clock className="w-4 h-4 mr-1" />
                  <span>{post.readTime}</span>
                </div>
                <div className="flex items-center">
                  <Tag className="w-4 h-4 mr-1" />
                  <span>{post.category}</span>
                </div>
              </div>
              <h3 className="text-xl font-bold text-indigo-700 mb-2">{post.title}</h3>
              <p className="text-indigo-600 mb-4">{post.excerpt}</p>
              <RetroButton onClick={() => navigate(`/blog/${post.id}`, { state: { isDesktopView } })}>
                Read More
              </RetroButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-violet-50 p-4 md:p-8 font-['VT323']">
      <div className="absolute inset-0 bg-[linear-gradient(rgba(99,102,241,0.1)_1px,transparent_1px),linear-gradient(90deg,rgba(99,102,241,0.1)_1px,transparent_1px)] bg-[size:20px_20px] [mask-image:radial-gradient(ellipse_80%_50%_at_50%_0%,#000_70%,transparent_110%)]" />
      <div className="max-w-6xl mx-auto space-y-6 relative">
        <RetroButton
          icon={<ArrowLeft className="w-5 h-5" />}
          onClick={handleBack}
        >
          Back to Portfolio
        </RetroButton>

        {isDesktopView ? (
          <WindowContainer title="BLOG_ARCHIVE.TXT">
            {content}
          </WindowContainer>
        ) : (
          <div className="bg-white border-2 border-indigo-500 rounded p-6 shadow-[4px_4px_0px_0px_#6366f1]">
            <h2 className="text-3xl font-bold text-indigo-700 mb-6">Blog Archive</h2>
            {content}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogPage;