import React from "react";
import { useNavigate } from "react-router-dom";
import RetroButton from "../RetroButton/RetroButton";
import { blogPosts } from "../../assets/components/blog-posts";

interface BlogSectionProps {
  isDesktopView: boolean;
}

const recentPosts = blogPosts.slice(0, 3);

const BlogSection: React.FC<BlogSectionProps> = ({ isDesktopView }) => {
  const navigate = useNavigate();

  const handlePostClick = (postId: number) => {
    navigate(`/blog/${postId}`, { state: { isDesktopView } });
  };

  return (
    <div className="space-y-4">
      {recentPosts.map((post) => (
        <div
          key={post.id}
          className="bg-white border-2 border-indigo-500 rounded p-4 shadow-[4px_4px_0px_0px_#6366f1] cursor-pointer hover:translate-y-[-2px] transition-transform"
          onClick={() => handlePostClick(post.id)}
        >
          <h3 className="font-bold text-xl mb-2 text-indigo-700">{post.title}</h3>
          <p className="text-indigo-600 mb-3">{post.description}</p>
          <div className="flex justify-between text-indigo-500 text-lg">
            <span>{post.date}</span>
            <span>READ TIME: {post.readTime}</span>
          </div>
        </div>
      ))}

      <div className="flex justify-center mt-6">
        <RetroButton onClick={() => navigate("/blog", { state: { isDesktopView } })}>
          READ_ALL_POSTS
        </RetroButton>
      </div>
    </div>
  );
};

export default BlogSection;