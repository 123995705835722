import { CareerItem } from "../../interfaces/career-item";
import { GraduationCap, Code } from "lucide-react";

export const careerHistory: CareerItem[] = [
  {
    year: "2024 - Present",
    title: "Full Stack Developer",
    organization: "Steamulo",
    description:
      "Building and maintaining web applications for clients. Working on both frontend and backend technologies.",
    icon: <Code className="w-6 h-6 text-indigo-500" />,
    technologies: ["React", "Node.js", "TypeScript", "PostGreSQL", "PHP Symfony"],
  },
  {
    year: "2023 - 2023",
    title: "Software Engineering Intern",
    organization: "Digital Surf",
    description:
      "Developing new features for MountainsMap, a software used for surface metrology and analysis.",
    icon: <Code className="w-6 h-6 text-indigo-500" />,
    technologies: ["C++", "Git", "OpenGL", "Python"],
  },
  {
    year: "2020 - 2023",
    title: "Computer Science Degree",
    organization: "Grenoble INP - Ensimag",
    description:
      "Pursuing a Bachelor's degree in Computer Science with a focus on software engineering and applied mathematics.",
    icon: <GraduationCap className="w-6 h-6 text-indigo-500" />,
    technologies: ["Java", "Python", "Algorithms", "Data Structures"],
  },
  {
    year: "2018 - 2020",
    title: "French CPGE",
    organization: "Lycée Victor Hugo",
    description:
      "Two years of intensive preparation for the competitive entrance exams to the French Grandes Écoles.",
    icon: <GraduationCap className="w-6 h-6 text-indigo-500" />,
    technologies: ["Python", "SQL", "OCaml", "Mathematics", "Physics"],
  }
  // Add more career items...
];
