import React, { useState, useEffect } from "react";
import { Code, ExternalLink, ArrowLeft, Github } from "lucide-react";
//import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import WindowContainer from "../components/WindowContainer/WindowContainer";
import RetroButton from "../components/RetroButton/RetroButton";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { projectsData } from "../assets/components/projects";
import { Project } from "../interfaces/project";

const ProjectPage: React.FC = () => {
  const [project, setProject] = useState<Project | null>(null);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const isDesktopView = location.state?.isDesktopView || false;

  useEffect(() => {
    const projectId = parseInt(id || '0', 10);
    const foundProject = projectsData.find(p => p.id === projectId);
    setProject(foundProject || null);
  }, [id]);

  const handleBack = () => {
    navigate("/projects", { state: { isDesktopView } });
  };

  if (!project) return <div>Project not found</div>;

  const content = (
    <div className="grid md:grid-cols-2 gap-6">
      <div>
        <div className="bg-indigo-100 aspect-video rounded-lg border-2 border-indigo-500 shadow-[4px_4px_0px_0px_#6366f1] flex items-center justify-center">
          {project.image ? (
            <img src={project.image} alt={project.title} className="w-full h-full object-cover rounded-lg" />
          ) : (
            <Code className="w-24 h-24 text-indigo-500" />
          )}
        </div>
        <div className="mt-4 flex space-x-4">
          {project.link && (
          <RetroButton icon={<ExternalLink className="w-5 h-5" />} onClick={() => window.open(project.link, '_blank')}>
            Live Demo
          </RetroButton>
          )}
          {project.github && (
            <RetroButton icon={<Github className="w-5 h-5" />} onClick={() => window.open(project.github, '_blank')}>
              Source Code
            </RetroButton>
          )}
        </div>
      </div>
      <div>
        <h1 className="text-3xl font-bold text-indigo-700 mb-4">
          {project.title}
        </h1>
        <div className="space-y-4 text-indigo-600">
          <p>{project.description}</p>
          <h2 className="text-xl font-bold text-indigo-700">
            TECH_STACK:
          </h2>
          <div className="flex flex-wrap gap-2">
            {project.tags.map((tech) => (
              <span
                key={tech}
                className="px-2 py-1 bg-white border-2 border-indigo-500 rounded shadow-[2px_2px_0px_0px_#6366f1]"
              >
                {tech}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-violet-50 p-4 md:p-8 font-['VT323']">
      <div className="max-w-6xl mx-auto space-y-6">
        <div className="" style={{ animationDelay: "200ms" }}>
          <RetroButton icon={<ArrowLeft className="w-5 h-5" />} onClick={handleBack}>
            Back to Projects
          </RetroButton>
        </div>
        {isDesktopView ? (
          <WindowContainer title={`PROJECT_${project.id}.EXE`}>
            {content}
          </WindowContainer>
        ) : (
          <div className="bg-white border-2 border-indigo-500 rounded p-6 shadow-[4px_4px_0px_0px_#6366f1]">
            <h2 className="text-3xl font-bold text-indigo-700 mb-6">Project Details</h2>
            {content}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectPage;