import React from "react";
import { careerHistory } from "../../assets/components/career-history";
import "./CareerSection.css";

const careerEvents = careerHistory;

const CareerSection: React.FC = () => (
  <div className="relative">
    {/* Vertical line */}
    <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-indigo-500 hidden md:block"></div>

    <div className="space-y-8">
      {careerEvents.map((event, index) => (
        <div key={index} className="group">
          <div className="relative flex items-start md:items-center gap-6">
            {/* Timeline dot */}
            <div
              className="absolute left-4 w-4 h-4 bg-indigo-500 rounded-full hidden md:block"
              style={{ marginLeft: "-6px" }}
            ></div>

            {/* Content card */}
            <div className="w-full md:ml-12 bg-white border-2 border-indigo-500 rounded p-4 shadow-[4px_4px_0px_0px_#6366f1] transition-all duration-300 hover:shadow-[6px_6px_0px_0px_#6366f1] hover:translate-x-[-2px] hover:translate-y-[-2px]">
              <div className="flex flex-col md:flex-row md:items-center justify-between mb-2">
                <div className="flex items-center gap-2">
                  <div className="w-10 h-10 bg-indigo-100 rounded flex items-center justify-center border-2 border-indigo-500">
                    {event.icon}
                  </div>
                  <h3 className="text-xl font-bold text-indigo-700">
                    {event.title}
                  </h3>
                </div>
                <span className="text-indigo-500 font-mono mt-2 md:mt-0">
                  {event.year}
                </span>
              </div>

              <p className="text-indigo-600 mb-2">{event.organization}</p>
              <p className="text-indigo-600 mb-4">{event.description}</p>

              {event.technologies && (
                <div className="flex flex-wrap gap-2">
                  {event.technologies.map((tech, techIndex) => (
                    <span
                      key={techIndex}
                      className="px-2 py-1 bg-indigo-50 border border-indigo-500 rounded text-indigo-600 text-sm"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default CareerSection;
