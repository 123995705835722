import React, { useState, useEffect } from "react";
import WindowContainer from "../components/WindowContainer/WindowContainer";
import RetroButton from "../components/RetroButton/RetroButton";
import { ArrowLeft, Calendar, Clock } from "lucide-react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { blogPosts } from "../assets/components/blog-posts";
import { BlogPost, BlogPostContent } from "../interfaces/blog-post";

const BlogPostPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [post, setPost] = useState<BlogPost | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const isDesktopView = location.state?.isDesktopView || false;

  useEffect(() => {
    const postId = parseInt(id || '0', 10);
    const foundPost = blogPosts.find(p => p.id === postId);
    setPost(foundPost || null);
    const timer = setTimeout(() => setIsLoading(false), 0);
    return () => clearTimeout(timer);
  }, [id]);

  const handleBack = () => {
    navigate("/blog", { state: { isDesktopView } });
  };

  if (isLoading || !post) return null; // or a loading component

  const renderContent = (content: BlogPostContent) => {
    switch (content.type) {
      case 'paragraph':
        return <p className="mb-4 text-indigo-600">{content.content}</p>;
      case 'heading':
        const HeadingTag = `h${content.level}` as keyof JSX.IntrinsicElements;
        return <HeadingTag className={`text-${4-(content.level ?? 1)}xl font-bold text-indigo-700 mt-6 mb-4`}>{content.content}</HeadingTag>;
      case 'code':
        return (
          <div className="bg-white border-2 border-indigo-500 rounded p-4 shadow-[4px_4px_0px_0px_#6366f1] my-6">
            <pre className="text-indigo-600 overflow-x-auto">
              <code>{content.content}</code>
            </pre>
          </div>
        );
      case 'image':
        return <img src={content.content} alt={content.alt} className="my-4 rounded-lg shadow-lg" />;
      default:
        return null;
    }
  };

  const content = (
    <article className="prose prose-indigo max-w-none">
      <h1 className="text-3xl font-bold text-indigo-700 mb-4">{post.title}</h1>
      
      {post.image && (
        <div className="mb-6">
          <img 
            src={post.image} 
            alt={post.title} 
            className="w-full h-64 object-cover rounded-lg shadow-[4px_4px_0px_0px_#6366f1] border-2 border-indigo-500"
          />
        </div>
      )}

      <div className="flex items-center space-x-4 text-indigo-500 mb-6">
        <div className="flex items-center">
          <Calendar className="w-5 h-5 mr-2" />
          <span>{post.date}</span>
        </div>
        <div className="flex items-center">
          <Clock className="w-5 h-5 mr-2" />
          <span>{post.readTime} READ</span>
        </div>
      </div>

      <div className="space-y-4">
        {post.content.map((item, index) => (
          <React.Fragment key={index}>
            {renderContent(item)}
          </React.Fragment>
        ))}
      </div>
    </article>
  );

  return (
    <div className="min-h-screen bg-violet-50 p-4 md:p-8 font-['VT323']">
      <div className="max-w-4xl mx-auto space-y-6">
        <div className="">
          <RetroButton
            icon={<ArrowLeft className="w-5 h-5" />}
            onClick={handleBack}
          >
            Back to Blog
          </RetroButton>
        </div>
        {isDesktopView ? (
          <WindowContainer title={`BLOG_${post.id}.TXT`}>{content}</WindowContainer>
        ) : (
          <div className="bg-white border-2 border-indigo-500 rounded p-6 shadow-[4px_4px_0px_0px_#6366f1]">
            {content}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogPostPage;