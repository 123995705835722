import React from "react";
import { ExternalLink } from "lucide-react";
import { useNavigate } from "react-router-dom";
import RetroButton from "../RetroButton/RetroButton";
import { projectsData } from "../../assets/components/projects";

const projects = projectsData;

interface ProjectsSectionProps {
  isDesktopView: boolean;
}

const ProjectsSection: React.FC<ProjectsSectionProps> = ({ isDesktopView }) => {
  const navigate = useNavigate();

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {projects.map((project) => (
          <div
            key={project.id}
            className="bg-white border-2 border-indigo-500 rounded p-4 shadow-[4px_4px_0px_0px_#6366f1]"
          >
            <div className="w-10 h-10 bg-indigo-100 rounded flex items-center justify-center mb-3 border-2 border-indigo-500">
              {project.icon}
            </div>
            <h3 className="font-bold text-xl mb-2 text-indigo-700">
              {project.title}
            </h3>
            <p className="text-indigo-600 mb-3">{project.description}</p>
            <RetroButton
              icon={<ExternalLink className="w-4 h-4" />}
              onClick={() => navigate(`/project/${project.id}`, { state: { isDesktopView } })}
            >
              Open Project
            </RetroButton>
          </div>
        ))}
      </div>

      <div className="flex justify-center mt-6">
        <RetroButton onClick={() => navigate("/projects", { state: { isDesktopView } })}>
          VIEW ALL PROJECTS
        </RetroButton>
      </div>
    </div>
  );
};

export default ProjectsSection;