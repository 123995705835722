import React, { useState, useRef, useEffect, ReactNode } from "react";
import { Minimize2, Maximize2 } from "lucide-react";

interface WindowContainerProps {
  title: string;
  children: ReactNode;
  className?: string;
  onClose?: () => void;
  isActive?: boolean;
  defaultWidth?: number;
  defaultHeight?: number;
  minWidth?: number;
  minHeight?: number;
}

interface DragOffset {
  x: number;
  y: number;
}

interface WindowSize {
  width: number;
  height: number;
}

type ResizeDirection = "n" | "s" | "e" | "w" | "ne" | "nw" | "se" | "sw" | null;

const WindowContainer: React.FC<WindowContainerProps> = ({
  title,
  children,
  className = "",
  onClose,
  isActive = false,
  defaultWidth = 800,
  defaultHeight = 600,
  minWidth = 400,
  minHeight = 300,
}) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState<ResizeDirection>(null);
  const [dragOffset, setDragOffset] = useState<DragOffset>({ x: 0, y: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [size, setSize] = useState<WindowSize>({
    width: defaultWidth,
    height: defaultHeight,
  });
  const windowRef = useRef<HTMLDivElement>(null);
  const resizeStartPos = useRef<{
    x: number;
    y: number;
    width: number;
    height: number;
  } | null>(null);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (windowRef.current && !isResizing) {
      setDragOffset({
        x: e.clientX - position.x,
        y: e.clientY - position.y,
      });
      setIsDragging(true);
    }
  };

  const handleResizeStart = (
    e: React.MouseEvent,
    direction: ResizeDirection
  ) => {
    e.stopPropagation();
    setIsResizing(direction);
    if (windowRef.current) {
      const rect = windowRef.current.getBoundingClientRect();
      resizeStartPos.current = {
        x: e.clientX,
        y: e.clientY,
        width: rect.width,
        height: rect.height,
      };
    }
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        const newX = e.clientX - dragOffset.x;
        const newY = e.clientY - dragOffset.y;
        setPosition({ x: newX, y: newY });
      } else if (isResizing && resizeStartPos.current) {
        e.preventDefault();
        const deltaX = e.clientX - resizeStartPos.current.x;
        const deltaY = e.clientY - resizeStartPos.current.y;
        const newSize = { ...size };
        const newPosition = { ...position };

        if (isResizing.includes("e")) {
          newSize.width = Math.max(
            minWidth,
            resizeStartPos.current.width + deltaX
          );
        }
        if (isResizing.includes("w")) {
          const newWidth = Math.max(
            minWidth,
            resizeStartPos.current.width - deltaX
          );
          if (newWidth !== size.width) {
            newSize.width = newWidth;
            newPosition.x = e.clientX - dragOffset.x;
          }
        }
        if (isResizing.includes("s")) {
          newSize.height = Math.max(
            minHeight,
            resizeStartPos.current.height + deltaY
          );
        }
        if (isResizing.includes("n")) {
          const newHeight = Math.max(
            minHeight,
            resizeStartPos.current.height - deltaY
          );
          if (newHeight !== size.height) {
            newSize.height = newHeight;
            newPosition.y = e.clientY - dragOffset.y;
          }
        }

        setSize(newSize);
        setPosition(newPosition);
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setIsResizing(null);
      resizeStartPos.current = null;
    };

    if (isDragging || isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, isResizing, dragOffset, minWidth, minHeight, position, size]);

  const handleToggleMinimize = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsMinimized(!isMinimized);
      setIsLoading(false);
    }, 500);
  };

  const resizeHandles = [
    { direction: "n", className: "top-0 left-0 right-0 h-1 cursor-n-resize" },
    {
      direction: "s",
      className: "bottom-0 left-0 right-0 h-1 cursor-s-resize",
    },
    { direction: "e", className: "top-0 right-0 bottom-0 w-1 cursor-e-resize" },
    { direction: "w", className: "top-0 left-0 bottom-0 w-1 cursor-w-resize" },
    { direction: "ne", className: "top-0 right-0 w-2 h-2 cursor-ne-resize" },
    { direction: "nw", className: "top-0 left-0 w-2 h-2 cursor-nw-resize" },
    { direction: "se", className: "bottom-0 right-0 w-2 h-2 cursor-se-resize" },
    { direction: "sw", className: "bottom-0 left-0 w-2 h-2 cursor-sw-resize" },
  ];

  return (
    <div
      ref={windowRef}
      className={`window-container bg-white rounded-lg shadow-[4px_4px_0px_0px_#6366f1] border-2 ${
        isActive ? "border-indigo-500" : "border-indigo-300"
      } ${className} ${isMinimized ? "window-minimized" : ""}`}
      style={{
        width: size.width,
        height: isMinimized ? "44px" : size.height,
        position: "absolute",
        left: position.x,
        top: position.y,
        transition:
          isResizing || isDragging ? "none" : "left 0.1s ease-out, top 0.1s ease-out, border-color 0.2s ease-in-out",
      }}
    >
      {/* Resize handles */}
      {!isMinimized &&
        resizeHandles.map(({ direction, className }) => (
          <div
            key={direction}
            className={`absolute ${className} bg-transparent hover:bg-indigo-500/10 z-50`}
            onMouseDown={(e) =>
              handleResizeStart(e, direction as ResizeDirection)
            }
          />
        ))}

      <div
        className={`flex items-center justify-between px-4 py-2 bg-gradient-to-r ${
          isActive
            ? "from-indigo-100 to-violet-100"
            : "from-gray-100 to-gray-200"
        } border-b-2 border-indigo-500 cursor-move select-none`}
        onMouseDown={handleMouseDown}
      >
        <div className="flex items-center space-x-2">
          <div className="flex space-x-1">
            <button
              className="w-3 h-3 bg-red-400 rounded-full border border-red-500 hover:bg-red-500 transition-colors duration-200"
              onClick={onClose}
              title="Close"
            />
            <button
              className="w-3 h-3 bg-yellow-400 rounded-full border border-yellow-500 hover:bg-yellow-500 transition-colors duration-200"
              onClick={handleToggleMinimize}
              title={isMinimized ? "Restore" : "Minimize"}
            />
            <div className="w-3 h-3 bg-green-400 rounded-full border border-green-500" />
          </div>
          <span className="font-['VT323'] text-lg text-indigo-700">
            {title}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          {isLoading && (
            <div className="loading-dots flex space-x-1">
              <div
                className="w-2 h-2 bg-indigo-500 rounded-full animate-bounce"
                style={{ animationDelay: "0s" }}
              />
              <div
                className="w-2 h-2 bg-indigo-500 rounded-full animate-bounce"
                style={{ animationDelay: "0.2s" }}
              />
              <div
                className="w-2 h-2 bg-indigo-500 rounded-full animate-bounce"
                style={{ animationDelay: "0.4s" }}
              />
            </div>
          )}
          <button onClick={handleToggleMinimize}>
            {isMinimized ? (
              <Maximize2 className="w-4 h-4 text-indigo-500" />
            ) : (
              <Minimize2 className="w-4 h-4 text-indigo-500" />
            )}
          </button>
        </div>
      </div>
      <div
        className={`transition-all duration-500 ease-in-out ${
          isMinimized ? "h-0" : ""
        }`}
        style={{
          height: isMinimized ? 0 : size.height - 44,
          opacity: isMinimized ? 0 : 1,
          overflow: "auto",
        }}
      >
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};

export default WindowContainer;
