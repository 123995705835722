import React, { useState } from "react";
import { Briefcase, Instagram, Twitter, Github, Send } from "lucide-react";
import RetroButton from "../RetroButton/RetroButton";

const ContactSection: React.FC = () => {
  const [message, setMessage] = useState("");

  const socialLinks = [
    { icon: <Briefcase className="w-5 h-5" />, label: "LinkedIn", url: "https://www.linkedin.com/in/bastienyoussfi" },
    { icon: <Twitter className="w-5 h-5" />, label: "Twitter", url: "https://x.com/bastienyoussfi_" },
    { icon: <Instagram className="w-5 h-5" />, label: "Instagram", url: "https://www.instagram.com/bastienyoussfi/" },
    { icon: <Github className="w-5 h-5" />, label: "GitHub", url: "https://github.com/bastienyoussfi" },
  ];

  const handleSendMessage = () => {
    const email = "bastien.youssfi@gmail.com";
    const subject = "Quick Message from Portfolio";
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="bg-indigo-100 p-6 rounded-lg border-2 border-indigo-500 shadow-[8px_8px_0px_0px_#6366f1]">
      <h2 className="text-3xl font-bold text-indigo-700 mb-6 text-center">CONTACT ME</h2>
      <div className="flex flex-col md:flex-row gap-8">
        <div className="md:w-1/2 bg-white p-4 rounded-lg border-2 border-indigo-500 shadow-[4px_4px_0px_0px_#6366f1]">
          <h3 className="font-bold text-xl mb-4 text-indigo-700">Contact Card</h3>
          <div className="space-y-2 mb-4">
            <p className="text-indigo-600">Name: Bastien Youssfi</p>
            <p className="text-indigo-600">Role: Full Stack Developer</p>
            <p className="text-indigo-600">Email: bastien.youssfi@gmail.com</p>
          </div>
          <div className="grid grid-cols-2 gap-2">
            {socialLinks.map((link, index) => (
              <RetroButton 
                key={index} 
                icon={link.icon} 
                onClick={() => window.open(link.url, '_blank')}
                className="text-sm py-1"
              >
                {link.label}
              </RetroButton>
            ))}
          </div>
        </div>
        <div className="md:w-1/2 bg-white p-4 rounded-lg border-2 border-indigo-500 shadow-[4px_4px_0px_0px_#6366f1]">
          <h3 className="font-bold text-xl mb-4 text-indigo-700">Quick Message</h3>
          <textarea
            rows={4}
            placeholder="Type your message here..."
            className="w-full px-3 py-2 mb-4 rounded bg-indigo-50 border-2 border-indigo-500 focus:outline-none focus:border-violet-500 text-indigo-700 font-['VT323'] text-lg"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            maxLength={500}
          ></textarea>
          <div className="flex justify-between items-center">
            <RetroButton 
              icon={<Send className="w-5 h-5" />}
              onClick={handleSendMessage}
              disabled={message.length === 0}
            >
              Send
            </RetroButton>
            <p className="text-indigo-500 text-sm">{message.length}/500</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;