import React, { useEffect } from "react";
import checkDarkTheme from "./utils/checkDarkTheme";
import Portfolio from "./pages/Portfolio";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BlogPostPage from "./pages/BlogPostPage";
import ProjectPage from "./pages/ProjectPage";
import BlogPage from "./pages/BlogPage";
import ProjectsPage from "./pages/ProjectsPage";

function App() {
  useEffect(() => {
    if (checkDarkTheme()) {
      document.documentElement.classList.add("dark");
      return;
    }
    document.documentElement.classList.remove("dark");
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/" element={<Portfolio />} />
          <Route path="/blog/:id" element={<BlogPostPage />} />
          <Route path="/project/:id" element={<ProjectPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
