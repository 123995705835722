import React, { useState } from "react";
import { skillsData } from "../../assets/components/skills";
import { ChevronLeft, ChevronRight } from "lucide-react";
import "./SkillsSection.css";

interface Skill {
  name: string;
  level: number;
}

const skills = skillsData;

const SkillBar: React.FC<{ skill: Skill; index: number }> = ({ skill, index }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative bg-white border-2 border-indigo-500 rounded p-2 text-indigo-600 text-center shadow-[2px_2px_0px_0px_#6366f1] hover:shadow-[4px_4px_0px_0px_#6366f1] transition-all duration-300 cursor-pointer overflow-hidden"
      style={{ animationDelay: `${index * 100}ms` }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span className={`relative z-10 transition-opacity duration-300 ${isHovered ? 'opacity-0' : 'opacity-100'}`}>
        {skill.name}
      </span>
      <div 
        className="absolute bottom-0 left-0 h-1 bg-indigo-500 transition-all duration-300 ease-out"
        style={{ width: isHovered ? `${skill.level * 20}%` : '0%' }}
      ></div>
      {isHovered && (
        <div className="absolute inset-0 bg-indigo-500 bg-opacity-90 flex items-center justify-center transition-opacity duration-300">
          <span className="text-white font-bold">Level: {skill.level}/5</span>
        </div>
      )}
    </div>
  );
};

const SkillsSection: React.FC = () => {
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  const nextCategory = () => {
    setCurrentCategoryIndex((prevIndex) =>
      prevIndex === skills.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevCategory = () => {
    setCurrentCategoryIndex((prevIndex) =>
      prevIndex === 0 ? skills.length - 1 : prevIndex - 1
    );
  };

  const currentCategory = skills[currentCategoryIndex];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <button
          onClick={prevCategory}
          className="p-2 rounded-full bg-indigo-500 text-white hover:bg-indigo-600 transition-colors"
        >
          <ChevronLeft size={24} />
        </button>
        <h3 className="font-bold text-2xl text-indigo-700">{currentCategory.title}</h3>
        <button
          onClick={nextCategory}
          className="p-2 rounded-full bg-indigo-500 text-white hover:bg-indigo-600 transition-colors"
        >
          <ChevronRight size={24} />
        </button>
      </div>
      <div className="bg-white border-2 border-indigo-500 rounded p-4 shadow-[4px_4px_0px_0px_#6366f1] transition-all duration-300">
        <div className="flex items-center mb-4">
          <div className="w-10 h-10 bg-indigo-100 rounded flex items-center justify-center border-2 border-indigo-500 mr-3">
            {currentCategory.icon}
          </div>
          <h3 className="font-bold text-xl text-indigo-700">
            {currentCategory.title}
          </h3>
        </div>
        <div className="grid grid-cols-2 gap-2">
          {currentCategory.skills.map((skill, skillIndex) => (
            <SkillBar key={skillIndex} skill={skill} index={skillIndex} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SkillsSection;