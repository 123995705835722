import React, { useEffect, useRef } from "react";

const RetroBackground = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Set canvas size with high pixel density
    const setCanvasSize = () => {
      const pixelRatio = window.devicePixelRatio || 1;
      canvas.width = window.innerWidth * pixelRatio;
      canvas.height = window.innerHeight * pixelRatio;
      canvas.style.width = `${window.innerWidth}px`;
      canvas.style.height = `${window.innerHeight}px`;
      ctx.scale(pixelRatio, pixelRatio);
    };

    setCanvasSize();

    // Generate random simple shapes
    const createShapes = () => {
      const shapes = [];

      for (let i = 0; i < 30; i++) {
        const shapeType = Math.random() > 0.5 ? "circle" : "square"; // Circle or Square
        const size = Math.random() * 80 + 20; // Random size between 20 and 100
        const x = Math.random() * canvas.width;
        const y = Math.random() * canvas.height;
        const color = `hsl(${Math.random() * 360}, 70%, 85%)`; // Light pastel colors
        const speed = Math.random() * 0.5 + 0.2; // Slow floating speed

        shapes.push({ shapeType, size, x, y, color, speed });
      }

      return shapes;
    };

    const shapes = createShapes();

    // Draw simple shapes on the canvas
    const drawShapes = () => {
      shapes.forEach((shape) => {
        ctx.fillStyle = shape.color;

        if (shape.shapeType === "circle") {
          // Draw Circle
          ctx.beginPath();
          ctx.arc(shape.x, shape.y, shape.size / 2, 0, Math.PI * 2);
          ctx.fill();
        } else {
          // Draw Square
          ctx.fillRect(shape.x - shape.size / 2, shape.y - shape.size / 2, shape.size, shape.size);
        }
      });
    };

    // Animate the shapes by making them float upwards slowly
    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      shapes.forEach((shape) => {
        shape.y -= shape.speed; // Move the shape upwards
        if (shape.y + shape.size < 0) {
          shape.y = canvas.height + shape.size; // Reset shape position to the bottom when it goes off screen
        }
      });

      drawShapes();

      requestAnimationFrame(animate);
    };

    animate();

    const handleResize = () => {
      setCanvasSize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="fixed inset-0 pointer-events-none"
      style={{ zIndex: 0 }}
    />
  );
};

export default RetroBackground;
