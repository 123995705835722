import React from "react";
import RetroButton from "../components/RetroButton/RetroButton";
import { Download, Send, FolderOpen } from "lucide-react";

interface HeroProps {
  onProjectsClick?: () => void;
  onContactClick?: () => void;
}

const Hero: React.FC<HeroProps> = ({ onProjectsClick, onContactClick }) => {
  const handleDownloadCV = () => {
    // Replace 'path/to/your/cv.pdf' with the actual path to your CV file
    const cvUrl = "CV.pdf";

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = cvUrl;
    link.setAttribute("download", "Bastien_Youssfi_CV.pdf"); // Set the desired file name
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-between py-16 select-none">
      <div className="md:w-1/2 mb-8 md:mb-0">
        <h1 className="text-4xl md:text-6xl font-bold text-indigo-700 mb-4">
          Bastien Youssfi
        </h1>
        <p className="text-xl md:text-2xl text-indigo-600 mb-4">
          Full Stack Developer | UI/UX Enthusiast
        </p>
        <p className="text-lg text-indigo-500 mb-6">
          Passionate about crafting elegant solutions to complex problems. With
          a keen eye for design and a love for clean code, I bring ideas to life
          through innovative web applications.
        </p>
        <div className="flex flex-wrap gap-4">
          <RetroButton
            icon={<FolderOpen className="w-5 h-5" />}
            onClick={onProjectsClick}
          >
            View Projects
          </RetroButton>
          <RetroButton
            icon={<Send className="w-5 h-5" />}
            onClick={onContactClick}
          >
            Contact Me
          </RetroButton>
          <RetroButton
            icon={<Download className="w-5 h-5" />}
            onClick={handleDownloadCV}
          >
            Download CV
          </RetroButton>
        </div>
      </div>
      <div className="md:w-1/2 flex justify-center items-center">
        <div className="relative max-w-sm w-full">
          {/* Pixelated corners */}
          <div className="absolute bottom-0 left-0 w-4 h-4 bg-indigo-500"></div>
          <div className="absolute bottom-0 right-0 w-4 h-4 bg-indigo-500"></div>

          {/* Image container */}
          <div
            className="
              relative
              border-4 border-indigo-500
              bg-white
              p-2
              transform rotate-2
              transition-transform hover:rotate-0 duration-300
              shadow-[8px_8px_0_0_#6366f1]
            "
          >
            <img
              src="photo.jpg"
              alt="Bastien Youssfi"
              className="w-full h-auto object-cover"
            />

            {/* Scanlines effect */}
            <div className="absolute inset-0 bg-scanlines pointer-events-none"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
