import React, { useState } from "react";
import { Code, Search, ArrowLeft } from "lucide-react";
import WindowContainer from "../components/WindowContainer/WindowContainer";
import RetroButton from "../components/RetroButton/RetroButton";
import { useNavigate, useLocation } from "react-router-dom";
import { projectsData } from "../assets/components/projects";

const LANGUAGES = ["All", "JavaScript", "TypeScript", "Python", "React", "Node.js"];

const ProjectsPage: React.FC = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktopView = location.state?.isDesktopView || false;

  const handleBack = () => {
    navigate("/");
  };

  const projects = projectsData;

  const filteredProjects = projects.filter((project) => {
    const languageMatch = selectedLanguage === "All" || project.tags.includes(selectedLanguage);
    const searchMatch = project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        project.description.toLowerCase().includes(searchTerm.toLowerCase());
    return languageMatch && searchMatch;
  });

  const content = (
    <div className="space-y-6">
      <div className="flex flex-col md:flex-row justify-between gap-4">
        <div className="relative">
          <input
            type="text"
            placeholder="SEARCH_PROJECTS"
            className="w-full md:w-64 px-4 py-2 bg-white border-2 border-indigo-500 rounded shadow-[2px_2px_0px_0px_#6366f1] focus:outline-none"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute right-3 top-2.5 w-5 h-5 text-indigo-500" />
        </div>

        <div className="flex flex-wrap gap-2">
          {LANGUAGES.map((lang) => (
            <button
              key={lang}
              onClick={() => setSelectedLanguage(lang)}
              className={`px-3 py-1 border-2 border-indigo-500 rounded ${
                selectedLanguage === lang
                  ? "bg-indigo-500 text-white"
                  : "bg-white text-indigo-500 hover:bg-indigo-100"
              } shadow-[2px_2px_0px_0px_#6366f1]`}
            >
              {lang}
            </button>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredProjects.map((project) => (
          <div
            key={project.id}
            className="bg-white border-2 border-indigo-500 rounded overflow-hidden shadow-[4px_4px_0px_0px_#6366f1]"
          >
            <img
              src={project.image}
              alt={project.title}
              className="w-full aspect-video object-cover"
            />
            <div className="p-4">
              <h3 className="text-xl font-bold text-indigo-700 mb-2">{project.title}</h3>
              <p className="text-indigo-600 mb-3">{project.description}</p>
              <div className="flex flex-wrap gap-2 mb-4">
                {project.tags.map((lang) => (
                  <span
                    key={lang}
                    className="px-2 py-1 bg-indigo-100 text-indigo-600 rounded text-sm border border-indigo-300"
                  >
                    {lang}
                  </span>
                ))}
              </div>
              <RetroButton 
                icon={<Code className="w-5 h-5" />}
                onClick={() => navigate(`/project/${project.id}`, { state: { isDesktopView } })}
              >
                View Project
              </RetroButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-violet-50 p-4 md:p-8 font-['VT323']">
      <div className="absolute inset-0 bg-[linear-gradient(rgba(99,102,241,0.1)_1px,transparent_1px),linear-gradient(90deg,rgba(99,102,241,0.1)_1px,transparent_1px)] bg-[size:20px_20px] [mask-image:radial-gradient(ellipse_80%_50%_at_50%_0%,#000_70%,transparent_110%)]" />
      <div className="max-w-6xl mx-auto space-y-6 relative">
        <RetroButton
          icon={<ArrowLeft className="w-5 h-5" />}
          onClick={handleBack}
        >
          Back to Portfolio
        </RetroButton>

        {isDesktopView ? (
          <WindowContainer title="ALL_PROJECTS.EXE">
            {content}
          </WindowContainer>
        ) : (
          <div className="bg-white border-2 border-indigo-500 rounded p-6 shadow-[4px_4px_0px_0px_#6366f1]">
            <h2 className="text-3xl font-bold text-indigo-700 mb-6">All Projects</h2>
            {content}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectsPage;