import React, { ReactNode } from "react";

interface RetroButtonProps {
  children: ReactNode;
  icon?: ReactNode;
  onClick?: () => void;
  className?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
}

const RetroButton: React.FC<RetroButtonProps> = ({
  children,
  icon,
  onClick,
  className = "",
  type = "button",
  disabled
}) => {
  return (
    <button
      type={type}
      className={`
        px-4 py-2 
        bg-white 
        border-2 border-indigo-500 
        rounded 
        shadow-[4px_4px_0px_0px_#6366f1] 
        hover:shadow-[2px_2px_0px_0px_#6366f1] 
        hover:translate-x-[2px] 
        hover:translate-y-[2px] 
        transition-all 
        text-indigo-500 
        font-bold 
        flex items-center 
        space-x-2
        ${className}
      `}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <span>{icon}</span>}
      <span>{children}</span>
    </button>
  );
};

export default RetroButton;